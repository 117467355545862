import React, { createContext, useState, useContext } from 'react';

const FolderHierarchyContext = createContext();

export const FolderHierarchyProvider = ({ children }) => {
  // Initialize with "root"
  const [folderPath, setFolderPath] = useState([{ id: 'root', name: 'Root' }]);

  return (
    <FolderHierarchyContext.Provider value={{ folderPath, setFolderPath }}>
      {children}
    </FolderHierarchyContext.Provider>
  );
};

// Custom hook for easier consumption
export const useFolderHierarchy = () => {
  return useContext(FolderHierarchyContext);
};
