// src/pages/BrowsingPage.js
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchFiles, triggerScan, processFile, downloadFile,fetchFolderName } from '../services/api';
import Breadcrumb from '../components/Breadcrumb';
import Notification from '../components/Notification';
import { ClipLoader } from 'react-spinners'; // Import a spinner library (like react-spinners or any other)

const placeholderImage = 'https://via.placeholder.com/180';

export default function BrowsingPage({ user }) {
  const { folderId = 'root' } = useParams();  
  const navigate = useNavigate();

  const [folders, setFolders] = useState([]);
  const [files, setFiles] = useState([]);
  const [folderPath, setFolderPath] = useState([{ id: 'root', name: 'Root' }]);
  const [notification, setNotification] = useState(null);

  useEffect(() => {

    const initializeFolderPath = async (folderId) => {
      try {
        const name = (await fetchFolderName(folderId)).data;
        setFolderPath([{id:'root',name:'Root'}, { id: folderId, name }]);
      } catch (err) {
        console.error('Error fetching folder path', err);
      }
    };

    if (folderId !== 'root') {
      initializeFolderPath(folderId);
    }
    // Whenever folderId changes, fetch the files/folders
    fetchFiles(folderId)
      .then(response => {
        const data = response.data;
        const folderItems = data.filter(item => item.mimeType === 'application/vnd.google-apps.folder');
        const fileItems = data.filter(item => item.mimeType !== 'application/vnd.google-apps.folder');

        setFolders(folderItems);
        setFiles(fileItems);

        // For demonstration, you can keep your local "folderPath" logic
        // Or remove it if you want to only rely on back/forward
        if (folderId === 'root') {
          setFolderPath([{ id: 'root', name: 'Root' }]);
        }
      })
      .catch(err => console.error('Error fetching files', err));
  }, [folderId]);

  const showNotification = (message, type = 'success') => {
    setNotification({ message, type });
    setTimeout(() => setNotification(null), 3000);
  };

  const handleFolderClick = (folderId, folderName) => {
    setFolderPath(prevPath => [...prevPath, { id: folderId, name: folderName }]);
    navigate(`/browse/${folderId}`);
  };

  const handleBreadcrumbClick = (index) => {
    // The user clicked on some crumb in the path
    const newPath = folderPath.slice(0, index + 1);
    setFolderPath(newPath);
    navigate(`/browse/${newPath[newPath.length - 1].id}`);
  };

  const scanNow = (folderId) => {
    triggerScan(folderId)
      .then(() => {
        showNotification(`Scan triggered successfully for folder ${folderId}.`);
      })
      .catch(err => {
        console.error('Error scanning now:', err);
        showNotification('Failed to trigger scan.', 'danger');
      });
  };

  const handleProcessFile = (fileId) => {
    // Set loading indicator for that file
    setFiles(prev =>
      prev.map(file => (file.id === fileId ? { ...file, loading: true } : file))
    );
    processFile(fileId)
      .then(response => {
        // Mark loading = false and store "result" (the public URL)
        const url = response.data.result;
        setFiles(prev => prev.map(file =>
          file.id === fileId ? { ...file, loading: false, result: url } : file
        ));
      })
      .catch(err => {
        console.error('Error processing file:', err);
        setFiles(prev => prev.map(file =>
          file.id === fileId ? { ...file, loading: false } : file
        ));
      });
  };

  const handleDownloadFile = async (fileId) => {
    // Option 1: Directly open the returned URL
    const downloadLink = await downloadFile(fileId);
    // window.open(downloadLink, '_blank');
      // Create a link element
      const link = document.createElement('a');
      link.href = downloadLink;
      // link.target = '_blank';
      link.rel = 'noopener noreferrer';
      link.setAttribute('download', '');
  
      // Append link to body and trigger click to initiate download
      document.body.appendChild(link);
      link.click();
      
      // Clean up by removing the link element
      document.body.removeChild(link);
  };

  return (
    <div className="container-fluid min-vh-100 d-flex flex-column align-items-center p-4 bg-light max-w-6xl">
      {notification && <Notification {...notification} />}
      <div className="align-self-start">
        <Breadcrumb folderPath={folderPath} onNavigate={handleBreadcrumbClick} />
      </div>
      {/* Example: If we're not in root, show a "Scan Now" button */}
      <div className="d-flex justify-content-center">

        <button className="btn btn-outline-success mx-2 mb-2" onClick={() => navigate(`/live/${folderId}`)}>
            Switch to <b><i>Folder View</i></b>
        </button>

        {folderId !== 'root' && (
          <button className="btn btn-primary mx-2 mb-2" onClick={() => scanNow(folderId)}>
            Scan <b><i>{folderPath[folderPath.length-1].name}</i></b> Now
          </button>
        )}
      </div>


      {/* Folders Section */}
      {/* {folders.length > 0 && <h3>Folders</h3>} */}
      <div className="row">
        {folders.map(folder => (
          <div key={folder.id} className="col-md-3 p-2">
            <div 
              className="card text-center p-3 shadow-sm hover-shadow cursor-pointer"
              onClick={() => handleFolderClick(folder.id, folder.name)}
            >
              <span className="text-primary">{folder.name}</span>
            </div>
          </div>
        ))}
      </div>

      {/* Files Section */}
      <div className="row row-cols-3 gx-2 gy-2">
        {files.map(file => (
          <div key={file.id}>
            <div className="card shadow-sm">
              <img
                src={file.thumbnail || placeholderImage}
                alt={file.name}
                className="card-img-top"
                style={{ height: '240px', objectFit: 'cover',width: '100%' }}
              />
              <div className="card-body">
                <h6 className="card-title d-flex align-items-center">
                  {/* “Process” button */}
                  <button 
                    onClick={() => handleProcessFile(file.id)}
                    className="btn btn-light mr-1"
                  >
                    🔗
                  </button>

                  {/* Download button */}
                  <button 
                    onClick={() => handleDownloadFile(file.id)}
                    className="btn btn-light"
                  >
                    📥
                  </button>

                  {/* File name */}
                  <span className="text-truncate flex-grow-1 ms-2">{file.name}</span>
                </h6>
                
                {/* File loading spinner or result */}
                <p class="card-text">{file.loading && (
                      <div className="d-flex justify-content-center">
                        <ClipLoader size={20} color={"#123abc"} loading={true} />
                      </div>
                    )}
                    {file.result && (
                      <div>
                         <button
                          onClick={() => navigator.clipboard.writeText(file.result)}
                          className="btn btn-light align-middle mr-2"
                        >
                          📋
                        </button>
                        
                        <span className="text-success text-truncate d-inline-block w-75 align-middle">{file.result}</span>
                       
                      </div>
                    )}
                    </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
