import React from 'react';
import './Breadcrumb.css'; // Import the CSS file

const Breadcrumb = ({ folderPath, onNavigate }) => {
  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        {folderPath.map((folder, index) => (
          <li
            key={folder.id || index}
            className={`breadcrumb-item ${
              index === folderPath.length - 1 ? 'active' : ''
            }`}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              if (index !== folderPath.length - 1) {
                onNavigate(index);
              }
            }}
          >
            {folder.name}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
