// src/pages/LoginPage.js
import React from 'react';
import { loginUrl } from '../services/api';

export default function LoginPage() {
  const handleLogin = () => {
    window.location.href = loginUrl(); // or window.open(loginUrl(), '_self');
  };

  return (
    <div className="login-page container-fluid min-vh-100 d-flex flex-column align-items-center bg-light">
      <h1 className="display-4 mb-5">Image Navigator</h1>
      <p>Please log in with Google</p>
      <button className="btn btn-primary" onClick={handleLogin}>
          Login with Google
      </button>
    </div>
  );
}
