// src/components/Notification.js
import React from 'react';

export default function Notification({ message, type }) {
  return (
    <div
      className={`alert alert-${type}`}
      style={{
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        zIndex: 9999,
        fontSize: '1.3em'
      }}
      role="alert"
    >
      {message}
    </div>
  );
}
