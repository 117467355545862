// src/pages/NotFoundPage.js
import React from 'react';

export default function NotFoundPage() {
  return (
    <div className="container text-center">
      <h1>404 - Not Found</h1>
      <p>The page you requested does not exist.</p>
    </div>
  );
}
