// src/App.js
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import { getProfile } from './services/api';
import NavigationBar from './components/NavigationBar';
import LoginPage from './pages/LoginPage';
import BrowsingPage from './pages/BrowsingPage';
import LivePage from './pages/LivePage';
import ConfigurationPage from './pages/ConfigurationPage';
import NotFoundPage from './pages/NotFoundPage';
import './App.css';

function App() {
  const [user, setUser] = useState(null);
  const [loadingUser, setLoadingUser] = useState(true); // for initial fetch loading

  useEffect(() => {
    // Fetch the profile on mount
    getProfile()
      .then((response) => {
        setUser(response.data); 
      })
      .catch(() => {
        setUser(null);
      })
      .finally(() => {
        setLoadingUser(false);
      });
  }, []);

  if (loadingUser) {
    // Simple "Loading..." or spinner
    return <div>Loading user data...</div>;
  }

  return (
    <Router>
      {/* Possibly a global nav bar */}
      <NavigationBar user={user} />

      <Routes>
        {/* If user is not logged in, go to <LoginPage />; otherwise <BrowsingPage /> */}
        <Route
          path="/"
          element={
            user ? <BrowsingPage user={user} /> : <LoginPage />
          }
        />

        {/* Browsing page with optional folderId param */}
        <Route
          path="/browse/:folderId?"
          element={<BrowsingPage user={user} />}
        />

        {/* Browsing page with optional folderId param */}
        <Route
          path="/live/:folderId?"
          element={<LivePage user={user} />}
        />

        {/* Configuration page */}
        <Route
          path="/config"
          element={<ConfigurationPage user={user} />}
        />

        {/* Catch-all for 404s */}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
}

export default App;
