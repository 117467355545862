// src/pages/ConfigurationPage.js
import React, { useState, useEffect } from 'react';
import { fetchFiles, toggleWeeklyScan, triggerScan } from '../services/api';
import Notification from '../components/Notification';
import Spinner from '../components/Spinner';

export default function ConfigurationPage({ user }) {
  const [folders, setFolders] = useState([]);
  const [notification, setNotification] = useState(null);
  const [toggleScanLoading, setToggleScanLoading] = useState({});

  useEffect(() => {
    // In your original code, the "root" call returns all top-level folders
    fetchFiles('root')
      .then(response => {
        const data = response.data;
        const folderItems = data.filter(item => item.mimeType === 'application/vnd.google-apps.folder');
        setFolders(folderItems);
      })
      .catch(err => console.error('Error fetching folders', err));
  }, []);

  const showNotification = (message, type = 'success') => {
    setNotification({ message, type });
    setTimeout(() => setNotification(null), 3000);
  };

  const handleToggleWeeklyScan = (folderId, status) => {
    setToggleScanLoading(prev => ({ ...prev, [folderId]: true }));

    toggleWeeklyScan(folderId, status)
      .then(() => {
        setFolders(prevFolders =>
          prevFolders.map(folder =>
            folder.id === folderId
              ? { ...folder, scanOnOff: status }
              : folder
          )
        );
        showNotification(`Weekly scan ${status ? 'enabled' : 'disabled'} successfully.`);
      })
      .catch(err => {
        console.error('Error toggling weekly scan:', err);
        showNotification('Failed to toggle weekly scan.', 'danger');
      })
      .finally(() => {
        setToggleScanLoading(prev => ({ ...prev, [folderId]: false }));
      });
  };

  const scanNow = (folderId,name) => {
    triggerScan(folderId)
      .then(() => {
        const date = new Date().toISOString(); // Or format as needed
        setFolders(prevFolders =>
          prevFolders.map(folder =>
            folder.id === folderId
              ? { ...folder, lastScan: date }
              : folder
          )
        );
        showNotification(`Scan triggered for folder ${name}.`);
      })
      .catch(err => {
        console.error('Error scanning now:', err);
        showNotification('Failed to trigger scan.', 'danger');
      });
  };

  return (
    <div className="container-fluid min-vh-100 d-flex flex-column align-items-center p-4 bg-light max-w-6xl">
      {notification && <Notification {...notification} />}

      {/* <h3>Folders Configuration</h3> */}
      <div className="row">
        {folders.map(folder => (
          <div key={folder.id} className="col-md-3 p-2">
            <div className="card shadow-sm p-3">
              <h5>{folder.name}</h5>
              <p>Last Scanned: {folder.lastScan || 'Never'}</p>
              <div className="d-flex justify-content-between">
                <button
                  className={`m-1 btn ${folder.scanOnOff ? 'btn-success' : 'btn-secondary'}`}
                  onClick={() => handleToggleWeeklyScan(folder.id, !folder.scanOnOff)}
                  disabled={toggleScanLoading[folder.id]}
                >
                  {toggleScanLoading[folder.id] ? (
                    <Spinner size={15} color="#fff" />
                  ) : (
                    folder.scanOnOff ? 'Disable Weekly Scan' : 'Enable Weekly Scan'
                  )}
                </button>
                <button
                  className="m-1 btn btn-primary"
                  onClick={() => scanNow(folder.id, folder.name)}
                >
                  Scan Now
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
